import React from 'react';
import './Data.css';

class Legend extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            // Colors defined in Data.css
            colors: [['forestgreen', 'No cases'],
            ['green', 'Cases decreasing'],
            ['yellow', 'Cases plateauing'],
            ['orange', 'Linear increase'],
            ['red', 'Exponential increase'],
            ['grey', 'Undefined cases']]
        }
    }
    // changeColor(color){
    //     var bodyStyles = document.body.style;
    //     bodyStyles.setProperty('--legend-color', color)
    // }
    // ('(255,85,0)', 'Decreasing Slope')
    // ('(255,0,0)', 'Exponential Increase')
    // ('(84,255,0)', 'Cases decreasing by more and more')
    // ('(255,170,0)', 'Near 0, but increasing')
    // ('(170,255,0)', 'Cases decreasing, but by less and less')
    // ('(255,255,0)', 'Near 0, but decreasing')
    // ('(0,255,0)', 'No Cases')
    legendText(){
        let color = [];
        // for(var c in this.state.colors){
        for(var i = 0; i< 6; i++){
            let c = this.state.colors[i]
            let classify = 'colors ' + c[0];
            color.push(
                <leg><leg class={classify}>bo</leg><leg id='meaning'> {c[1]}</leg><br></br></leg>
            );
        }
        return (
            <div> {color}</div>
        );
    }
    render() {
        let color = [];
        // for(var c in this.state.colors){
        for(var i = 0; i<this.state.colors.length; i++){
            let c = this.state.colors[i]
            let classify = 'colors ' + c[0];
            color.push(
                <div id='container'><leg class={classify}>b </leg><leg id='meaning'> {c[1]}</leg></div>
            );
        }
        return (
            <div id='aligner'>
                {color}
            </div>
            // <div>{this.legendText()}</div>
        )
    }
}

export default Legend;