import React, { Component } from "react";
// import {Redirect} from 'react-router';
import './Data.css';

class Error extends Component {
    constructor(props){
        super(props)
        this.url = 'https://covid19-map.com'

    }
    render() {
      return (
          <div style = {{fontFamily: 'Courier New'}}>
            <h1><paddingbox> ERROR 404 </paddingbox></h1>
            <div style = {{fontFamily: 'Courier New'}}>
            <paddingbox>
            page not found
            </paddingbox>
            </div>
            <div style = {{fontWeight: '600'}}>
          <div style= {{fontStyle: 'italic'}}className="genre-search-title" onClick={() => this.props.history.push('/')}><paddingbox>
            click to return to {this.url} </paddingbox>
          </div>
          </div>
          </div>
      );
    }

}

export default Error;
