import React, { useState } from "react";
import LeafletMap from "./LeafletMap.js";
import About from "./About.js"
import "bootstrap/dist/css/bootstrap.min.css";
import { Navbar } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import "./styles/App.css";


const App = () => {
    const map = <LeafletMap></LeafletMap>
    const about = <About></About>
    const [view, setView] = useState(map);

    const onSelect = selectedKey => {
        switch (selectedKey) {
            case "map":
                setView(map);
                break;
            case "about":
                setView(about);
                break;
            default:
                setView(map);
        }
    };

    return (
        <div>
            <Navbar className = 'color-nav' expand="lg">
                <Navbar.Brand>COVID-19 Map</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav
                        activeKey="map"
                        onSelect={selectedKey => onSelect(selectedKey)}
                    >
                        <Nav.Item>
                            <Nav.Link eventKey="map">Map</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="about">About</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            {view}
        </div>
    );
};

export default App;
