import React, { Component } from "react";
import { Line } from "react-chartjs-2";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/CasesChart.css";
import downArrow from "./styles/Images/downArrow.png";
import upArrow from "./styles/Images/upArrow.png";
import downArrowDark from "./styles/Images/downArrowDark.png";
import upArrowDark from "./styles/Images/upArrowDark.png";

class Chart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chartData: {},
            data: {},
            latest: "",
            region: "",
            display: true,
            on: false, //false is down, true is up
            buttonDirection: downArrow,
            darkArrow: downArrowDark,
        };
    }

    toggle = () => {
        this.setState({
            on: !this.state.on,
        });
        if (this.state.on === false) {
            this.setState({
                buttonDirection: upArrow,
                darkArrow: upArrowDark,
            });
        } else {
            this.setState({
                buttonDirection: downArrow,
                darkArrow: downArrowDark,
            });
        }
    };
    //Takes currency, gives back [dates,xrates]
    async setData() {        
        let myObj = require('./data/global.json')

        let retDict = {};
        for (let index in myObj) {
            let tempDates = [];
            let tempVals = [];
            for (let date in myObj[index]) {
                tempVals.push(myObj[index][date]);
                tempDates.push(date);
                
            }
            let t = index;
            retDict[t] = [tempDates, tempVals];
        }
        
        await this.setState({ data: retDict });
        
    }

    getData(index) {
        let dates = [];
        let vals = [];
        
        try{
        for (let date in this.state.data[index][0]) {
            dates.push(this.state.data[index][0][date]);
        }
        for (let v in this.state.data[index][1]) {
            vals.push(this.state.data[index][1][v]);
        }
        } catch( err){
            console.log(this.state.data);
            console.log(index)
            throw err;
        }
        let ret = [dates, vals];
        return ret;
    }

    async updateChart(index) {
        let index_data = this.getData(index);
        let dates = index_data[0];
        let vals = index_data[1];

        this.setState({
            current_index: index,
            region: index,
            chartData: {
                labels: dates,
                datasets: [
                    {
                        label: "Confirmed Cases",
                        data: vals,
                        fill: false,
                        borderColor: "#cc4125",
                        backgroundColor: "#cc4125",
                        pointBackgroundColor: "#cc4125",
                        pointBorderColor: "#cc4125",
                        pointHoverBackgroundColor: "#a3341d",
                        pointHoverBorderColor: "#a3341d",
                    },
                ],
            },
        });
    }

    async componentWillMount() {
        await this.setData();
        this.updateChart(this.props.country); //default indence
    }

    //updates chart for specific country
    onClickHandler = (event) => {
        let newIndex = event.target.innerText;
        this.updateChart(newIndex);
    };

    collapse = () => {
        this.setState({
            display: false,
        });
        this.render();
    };

    render() {
        if (this.props.country !== undefined) {
            if (
                this.props.country !== this.state.latest &&
                this.props.country in this.state.data
            ) {
                this.updateChart(this.props.country);
                let r = this.props.country;
                if (this.props.country.includes("-")) {
                    r = this.props.country.split("-")[0];
                }
                this.setState({
                    latest: this.props.country,
                    region: r,
                });
            }
        }

        // function collapse (){
        //     let ting = document.getElementById("graphCollapse");
        //     this.setState({
        //         display: false
        //     });
        // }

        return (
            <div id="graphArea">
                <div class="collapseDiv" style={{ minWidth: "200px" }}>
                    {!this.state.on && (
                        <span
                            style={{
                                color: "#696969",
                                fontSize: "30px",
                                fontFamily: "Arial",
                                verticalAlign: "middle",
                                paddingTop: '10px',
                                float: "left",
                            }}
                        >
                            Casegraph
                        </span>
                    )}
                    <img
                        style={{ float: "right", verticalAlign: "middle" }}
                        src={this.state.buttonDirection}
                        onMouseOver={(e) =>
                            (e.currentTarget.src = this.state.darkArrow)
                        }
                        onMouseOut={(e) =>
                            (e.currentTarget.src = this.state.buttonDirection)
                        }
                        height="40"
                        width="40"
                        alt="alt"
                        onClick={this.toggle}
                    />
                </div>

                {this.state.on && (
                    <div className="chart" id="graphCollapse">
                        <Line
                            data={this.state.chartData}
                            // style= "display" : {this.state.display}
                            options={{
                                display: this.state.display,
                                title: {
                                    display: true,
                                    text: "# of Cases for " + this.state.region,
                                    fontColor: "#383838",
                                    fontSize: 25,
                                    fontFamily: "Quicksand",
                                },
                                hover: {
                                    mode: "nearest",
                                    intersect: true,
                                },
                                legend: {
                                    display: false,
                                    position: "bottom",
                                    labels: {
                                        fontFamily: "Quicksand",
                                        fontColor: "#282828",
                                    },
                                },
                                elements: {
                                    line: {
                                        tension: 0,
                                    },
                                },
                                scales: {
                                    xAxes: [
                                        {
                                            ticks: {
                                                fontFamily: "Quicksand",
                                                fontSize: 15,
                                            },
                                            scaleLabel: {
                                                display: true,
                                                labelString: "Date",
                                                fontSize: 20,
                                                fontFamily: "Quicksand",
                                                fontColor: "#282828",
                                            },
                                        },
                                    ],
                                    yAxes: [
                                        {
                                            ticks: {
                                                fontFamily: "Quicksand",
                                                fontSize: 15,
                                            },
                                            scaleLabel: {
                                                display: true,
                                                labelString: "# of Cases",
                                                fontSize: 20,
                                                fontFamily: "Quicksand",
                                                fontColor: "#282828",
                                            },
                                        },
                                    ],
                                },
                            }}
                        />
                    </div>
                )}
            </div>
        );
    }
}

export default Chart;
