import React from 'react';
import {Route, Switch, BrowserRouter as Router } from "react-router-dom";

import Error from './error.js';
import App from './App.js';


const Routes =() =>{
    return(
    <Router>
     <Switch>
        <Route exact path="/"component={App}/>
        <Route exact path="/error"component={Error}/>
        <Route component={Error}/>
    </Switch>
    </Router>
    );
};

export default Routes