// @flow
import React, { createRef, useEffect, useState } from "react";
import { Map, TileLayer, GeoJSON } from "react-leaflet";
import L from "leaflet";
import Casegraph from "./Casegraph.js";
import ReactDOM from "react-dom";
import './Data.css';
import Legend from './legend.jsx';
import Control from "react-leaflet-control";
import "./styles/CasesChart.css";

var customBounds = {
    "United States of America": [[50, -70],[35.0000,-130]],
    "Alaska": [[72.559290,-140],[51.2,-186.004319]],
    "Canada": [[74.6773358417,-49.6668184285],[40.3209806691,-143.7406068054]],
    "France": [[41.9281506772,-5.8970008063],[51.6370183287,9.5523234029]],
    "Russia": [[78, 180], [38,20]]
}

var colorDict = require("./data/color.json");
var statusDict = require("./data/status.json");

var defaultData = require('./data/countries_110.json');
var mapData = {}
for (let index in defaultData.features){
    mapData[defaultData.features[index].properties.ADMIN] = defaultData.features[index];
}
var backup = JSON.parse(JSON.stringify(mapData));
var big_countries = require('./data/big_countries.json');

var xhr = new XMLHttpRequest()
xhr.addEventListener('load', () => {
})

const LeafletMap = (props) => {
    let initialized = false;
    var mapRef = createRef<Map>();
    var geoJSONRef = createRef<GeoJSON>();
    var [info, setInfo] = useState(L.control());
    var [graph, setGraph] = useState(L.control());

    const handleClick = (event) => {
    };

    var mapboxAccessToken =
        "pk.eyJ1IjoiY29yb25hdHJhY2tlciIsImEiOiJjazkzOTdtbDcwMmI0M2VxbjVtZHcwd2VuIn0.kKpmEGWj7mKvzeIp3-U-Ug";

    const getColor = (name) => {
        if (name in colorDict) {
            return colorDict[name]
        }
        return "#999991";
    };
    
    const style = (feature) => {
        return {
            fillColor: getColor(
                feature.properties.ADMIN || feature.properties.name_en
            ),
            weight: 1,
            opacity: 1,
            color: "white",
            dashArray: "3",
            fillOpacity: 0.7,
        };
    };

    const highlightFeature = (e) => {
        var layer = e.target;

        layer.setStyle({
            weight: 2,
            color: "#666",
            dashArray: "",
            fillOpacity: 0.7,
        });

        if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
            layer.bringToFront();
        }
        info.update(layer.feature.properties);
        graph.update(layer.feature.properties);
    };

    const resetHighlight = (e) => {
        geoJSONRef.current.leafletElement.resetStyle(e.target);
        info.update();
        graph.update();
    };

    const zoomToFeature = (e) => {        
        if (!initialized){
            init();
        }
        if (e.target.feature.properties.ADMIN in customBounds){
            mapRef.current.leafletElement.fitBounds(customBounds[e.target.feature.properties.ADMIN]);
        }
        else if (e.target.feature.properties.name_en in customBounds){
            mapRef.current.leafletElement.fitBounds(customBounds[e.target.feature.properties.name_en]);
        }
        else{
            mapRef.current.leafletElement.fitBounds(e.target.getBounds());
        }
        if (big_countries.includes(e.target.feature.properties.ADMIN)) {
            xhr.open('GET', 'https://raw.githubusercontent.com/gnanduru1/CoronaTrackerAPI/master/big_countries/'+e.target.feature.properties.ADMIN+'.json', false);
            xhr.send();
            mapData[e.target.feature.properties.ADMIN] = JSON.parse(xhr.responseText);
            init();
        }
    };

    const onEachFeature = (feature, layer) => {
        layer.on({
            mouseover: highlightFeature,
            mouseout: resetHighlight,
            click: zoomToFeature,
        });
    };
    const reset = () => {     
        mapData = JSON.parse(JSON.stringify(backup));
        init()
    };
    const init = () => {
       if (geoJSONRef.current==undefined){
           return;
       }
        geoJSONRef.current.leafletElement.clearLayers();
        for (let name in mapData){
            geoJSONRef.current.leafletElement.addData(mapData[name]);
        }
        initialized = true;
    }

    useEffect(() => {
        //    var info = L.control();
        info.onAdd = function (map) {
            this._div = L.DomUtil.create("div", "info"); // create a div with a class "info"
            this.update();
            return this._div;
        };


        // method that we will use to update the control based on feature properties passed
        info.update = function (props) {
            if (props != undefined) {
                let name = props.ADMIN;
                if (name === undefined) {
                    name = props.name_en;
                }
                let data = statusDict[name];

                if (data === undefined) {
                    data = statusDict[props.name_en];
                }
                if (data === undefined) {
                    console.log(props);
                    console.log(name);

                    data = 'Undefined'
                }
                this._div.innerHTML =
                    "<h4>COVID-19 Interactive Map</h4>" +
                    (props
                        ? "<b>" + name + "</b><br />" + data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " cases"
                        : "Hover over a region");
            }
        };

        info.addTo(mapRef.current.leafletElement);

        graph.onAdd = function (map) {
            this.div = L.DomUtil.create("div", "graph");
            const jsx = (
                <div className="infoDiv">
                    <Casegraph country="Canada" />
                </div>
            );
            ReactDOM.render(jsx, this.div);
            return this.div;
        };

        graph.update = function (props) {
            if (props != undefined) {
                let name = props.ADMIN;
                if (name === undefined) {
                    name = props.name_en;
                }
                const jsx = (
                    <div className="infoDiv">
                        <Casegraph country={name} />
                    </div>
                );
                ReactDOM.render(jsx, this.div);
                return this.div;
            }
        };

        graph.addTo(mapRef.current.leafletElement);

        init();
    }, []);
    
    return (
        <div>
            <Map
                center={{ lat: 37.8, lng: -96 }}
                length={4}
                onClick={handleClick}
                ref={mapRef}
                zoom={4}
                style={{ height: window.innerHeight - 60 }}
            >
                <TileLayer
                    url={
                        "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=" +
                        mapboxAccessToken
                    }
                    id="mapbox/light-v9"
                    attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    tileSize={512}
                    zoomOffset={-1}
                />

                <GeoJSON
                    data={defaultData}                  
                    style={style}
                    onEachFeature={onEachFeature}
                    ref={geoJSONRef}
                />                
                <Control position="topleft">
                    <button id='style' onClick={() => reset()}>Reset map</button>
                </Control>

                <Control position="bottomleft">
                    <div id='legendbox'> Colors <Legend></Legend></div>
                </Control>

            </Map>
        </div>
    );
};

export default LeafletMap;