import React from 'react';
import './Data.css';
class About extends React.Component{
    render() {
        return (
            <div>
                <h3>
                    About COVID-19 Map
                </h3>
                <p>
                    Click on a country to divide it into regions. <br></br>
                    Currently detailable countries are
                    India, United Kingdom, France, United States of America, Denmark, Netherlands, Italy, Spain, Australia, Canada, China, and New Zealand
                    <br></br><br></br>
                    Built with ReactJS, Leaflet, and OpenStreetMap.
                </p>
                <a href="https://github.com/gnanduru1/CoronaTrackerAPI" rel="noopener noreferrer" class="button" target="_blank">
                    Data Collection</a>
            </div>
        )
     }
}

export default About;